'use client';

import { getCalApi } from '@calcom/embed-react';
import Image from 'next/image';
import { useEffect } from 'react';
import { Button } from '../ui/button';
import plusIcon from '/public/vectors/plus-circle-icon.svg';

interface CalButtonProps {
	link: string;
	namespace: string;
	btnText?: string;
}

export default function CalButton({
	link,
	namespace,
	btnText = 'Book now',
}: CalButtonProps) {
	useEffect(() => {
		(async function () {
			const cal = await getCalApi({ namespace: namespace });
			cal('ui', {
				theme: 'dark',
				cssVarsPerTheme: {
					light: {},
					dark: {},
				},
				hideEventTypeDetails: false,
				layout: 'month_view',
			});
		})();
	}, [namespace]);

	return (
		<Button
			className="bg-yellow-secondary font-recursive font-medium tracking-tight text-black transition-all duration-300 hover:bg-white hover:font-semibold"
			data-cal-namespace={namespace}
			data-cal-link={`${link}/${namespace}`}
			data-cal-config='{"layout":"month_view"}'
		>
			<Image
				src={plusIcon}
				alt="Add icon"
				aria-hidden="true"
				className="mr-1 size-4 shrink-0"
			/>
			{btnText}
		</Button>
	);
}
