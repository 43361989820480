import { cn } from '@/src/lib/utils';

export function TypographyParagraph({ children, className }) {
	return (
		<p
			className={cn(
				'text-pretty text-base font-normal text-black',
				className
			)}
		>
			{children}
		</p>
	);
}
